document.addEventListener('DOMContentLoaded',() => {
  iniciarMenu(); 
  //observadorNavegacion();

});

function iniciarMenu(){
  const menuPrincipal = document.querySelector('.nav-icon-burger');
  if(menuPrincipal){
    menuPrincipal.addEventListener('click', e => {
      console.log("clic");
      const hmenu = document.querySelector('.nav-menu-items');
      const estadoMenu = document.querySelector('.menu-activado');
      if(estadoMenu){
        estadoMenu.classList.remove('menu-activado');
      }
      else{
        hmenu.classList.add('menu-activado');
      }
    });
  }
}




function getViewportWidth() {
var viewPortWidth;
if (typeof window.innerWidth != 'undefined') {
  viewPortWidth = window.innerWidth;
}
else if(typeof document.documentElement != 'undefined' && 
        typeof document.documentElement.clientWidth != 'undefined' && 
        document.documentElement.clientWidth != 0) {
  viewPortWidth = document.documentElement.clientWidth;
}
else {
  viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
}
return viewPortWidth;
}

function getAbsElementPositionTop(element) {
var y = 0;
if (typeof element == "string")
  element = document.querySelector(element)
if (!element) return 0;

while (element.offsetParent) {
  y += element.offsetTop;
  element = element.offsetParent;
}
return y;
}