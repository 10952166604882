popupWhatsApp = () => {
  
    let btnClosePopup = document.querySelector('.closePopup');
    let btnOpenPopup = document.querySelector('.whatsapp-button');
    let popup = document.querySelector('.popup-whatsapp');
    let sendBtn = document.getElementById('send-btn');
  
    btnClosePopup.addEventListener("click",  () => {
      popup.classList.toggle('is-active-whatsapp-popup')
    })
    
    btnOpenPopup.addEventListener("click",  () => {
      popup.classList.toggle('is-active-whatsapp-popup')
       popup.style.animation = "fadeIn .6s 0.0s both";
    })
    
    sendBtn.addEventListener("click", () => {
    let msg = document.getElementById('whats-in').value;
    let relmsg = msg.replace(/ /g,"%20"); 
       
     window.open('https://wa.me/+525575948710?text='+relmsg, '_blank'); 
    
    });
  
    /* setTimeout(() => {
      popup.classList.toggle('is-active-whatsapp-popup');
    }, 3000); */
  }
  
popupWhatsApp();

// Facebook


// Translate
$.getJSON('language.json',function(json){
  $('#chk-lang').click(function(){
    let lang = $(this);
    if($(this).is(":checked")) {
      $('.lang').each(function(index,value){
        $(this).text(json["en"][$(this).attr('key')]);
        $(this).attr("placeholder",json["en"][$(this).attr('key')] );
        console.log(value);
        
      });
      console.log("en");
  } else {
    $('.lang').each(function(index,vaue){
      $(this).text(json["es"][$(this).attr('key')]);
      $(this).attr("placeholder",json["es"][$(this).attr('key')] );
    });
    console.log("es");
  }
  });
});